/* eslint-env browser */

function youTubePlayer() {
	let div;
	let n;
	const v = document.getElementsByClassName('youtube-player');

	const _thumbnail = (id) => {
		const thumb = '<img src="https://i.ytimg.com/vi/ID/hqdefault.jpg" alt="Play the YouTube Video ' + id +'">';
		let play = '<div class="play" id="youtube-video"></div>';
		return thumb.replace('ID', id) + play;
	};

	// Custom function for poster.
	const _poster = function () {
		if (document.querySelector('.poster--video')) {
			const p = this.parentNode;
			p.parentNode.parentNode.classList.add('poster--video-playing');
		}
	};

	const _iframe = function () {
		const iframe = document.createElement('iframe');
		const embed = 'https://www.youtube.com/embed/ID?autoplay=1&rel=0&amp;showinfo=0';
		iframe.setAttribute('src', embed.replace('ID', this.getAttribute('data-id')));
		iframe.setAttribute('frameborder', '0');
		iframe.setAttribute('allowfullscreen', '1');
		this.parentNode.replaceChild(iframe, this);
	};

	const _init = function () {
		for (n = 0; n < v.length; n++) {
			div = document.createElement('div');
			div.setAttribute('data-id', v[n].getAttribute('data-id'));
			div.innerHTML = _thumbnail(v[n].getAttribute('data-id'));
			div.addEventListener('click', _poster); // Custom click event for poster.
			div.addEventListener('click', _iframe);
			v[n].appendChild(div);
		}
	};

	_init();
}

export default youTubePlayer;
