// Get dom element selector.
const dom = {
	select: document.querySelector.bind(document),
	selectAll: document.querySelectorAll.bind(document)
};

export {dom};

// Get breakpoints from CSS.
const breakpoint = () => {
	const b = window.getComputedStyle(
		dom.select('#breakpoint'),
		':after'
	).getPropertyValue('content').replace(/"|'/g, '');

	return b;
};

export {breakpoint};

// Throttle triggered Events
// Wrap your actions in this function to throttle the frequency of firing them off
const throttle = (() => {
	const timers = {};
	return (callback, ms, uniqueId) => {
		if (!uniqueId) {
			uniqueId = 'Don\'t call this twice without a uniqueId';
		}
		if (timers[uniqueId]) {
 clearTimeout(timers[uniqueId]);
		}
		timers[uniqueId] = setTimeout(callback, ms);
	};
})();

export {throttle};
