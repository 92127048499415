/* eslint-env browser */

function lazyLoad() {
	const elements = document.querySelectorAll('iframe, img, source');

	const _preload = element => {
		// element.dataset && element.dataset.src
		if (element.getAttribute('data-src')) {
			element.src = element.getAttribute('data-src');
		}

		// element.dataset && element.dataset.srcset
		if (element.getAttribute('data-srcset')) {
			element.srcset = element.getAttribute('data-srcset');
		}
	};

	const _loadImmediately = elements => {
		Array.from(elements).forEach(element => this._preload(element));
	};

	if (!('IntersectionObserver' in window)) {
		_loadImmediately(elements);
		return;
	}

	const config = {
		// That means the callback function will execute when an image element is within 500 pixels of the viewport,
		rootMargin: '0px 0px 500px 0px', // Default 0px 0px 250px 0px
		threshold: 0
	};

	const observer = new IntersectionObserver(((entries, self) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				// Console.log(`Image ${entry.target.src} is in the viewport!`);
				_preload(entry.target);
				// Stop watching and load the image
				self.unobserve(entry.target);
			}
		});
	}), config);

	elements.forEach(element => {
		observer.observe(element);
	});
}

export default lazyLoad;
