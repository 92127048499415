import {dom, breakpoint, throttle} from './javascript/_utils';
import lazyLoad from './javascript/_lazy-load';
import youTubePlayer from './javascript/_youtube';
import 'a11y_tab_widgets';

import './main.css';

/* eslint-env browser */

(() => {
	'use strict';

	if (!('querySelector' in document && 'localStorage' in window && 'addEventListener' in window)) {
		return;
	}

	let viewport = {};

	// Menu Toggle
	const page = dom.select('#page');
	const menu = dom.select('.menu');
	const menuButton = dom.select('.menu-button');

	function menuToggle() {
		// Add (initial) button semantics
		menuButton.classList.add('enhanced');
		menuButton.setAttribute('aria-haspopup', true);
		menuButton.setAttribute('aria-expanded', false);

		const toggle = () => {
			const toggled = menuButton.getAttribute('aria-expanded');

			if (toggled === 'false') {
				menuButton.setAttribute('aria-expanded', true);
				menu.setAttribute('style', 'visibility: visible; opacity: 1;');
				menu.classList.add('menu--open');
				page.classList.add('menu-is-open');
			} else {
				menuButton.setAttribute('aria-expanded', false);
				menu.setAttribute('style', 'visibility: hidden; opacity: 0;');
				menu.classList.remove('menu--open');
				page.classList.remove('menu-is-open');
			}
		};

		menuButton.addEventListener('click', toggle);
	}

	window.addEventListener('load', () => {
		viewport = breakpoint();
		lazyLoad();
		menuToggle();
		youTubePlayer();

		var tabs = document.querySelectorAll('[data-atabs]');
		var tabsOptions = {
			tabListClass: 'atabs-list',
			tabClass: 'atabs-list-tab',
			panelClass: 'atabs-panel'
		};

		// Generate all Tab instances
		for (var i = 0; i < tabs.length; i++) {
			var nTabs = new ARIAtabs(tabs[i], tabsOptions);
		}

		//console.log('bp: ' + viewport);
	});

	window.addEventListener('resize', () => {
		throttle(() => {
			viewport = breakpoint();
			//console.log('bp: ' + viewport);
		}, 100, 'resizeEvent');
	});
})();
